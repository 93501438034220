import React from 'react'
function s2015() {
  return (
    <div>
        <h4>PClassic 2015 Spring Summary</h4>
<p>
PClassic happened this spring, on March 21st, 2015. It grew to its largest size yet for a spring contest, with around 180 students on 55 teams from over 15 schools. Participants came from Pennsylvania, Maryland, New Jersey, New York, and Virginia! </p>
<br />
<p>
The top 3 teams this year in the Standard division  were:
<br />
1. <i>Unaffiliated from Virginia</i>, 9/9 correct - Matthew Savage, Danial Hussain, Hyo Won Kim, Samuel Hsiang
<br />
2. <i>Stuyvesant High School</i>, 7/9 correct - Calvin Lee, Gideon Leeper
<br />
3. <i>Unaffiliated from Virginia</i> 7/9 correct - Lalit Varada, Samuel Kim, Rajat Khanna, Haoyuan Sun
<br />
4. <i>Unaffiliated from Virginia</i> 7/9 correct - Jason Lam, Charles Zhao, Kevin Geng, Lawrence Wang
<br /> <br />
Honorable mention to three other teams who also solved 7 problems correctly:
<br />
<i>Montgomery Blair</i> - Noah Singer, Arman Siddique, Andrew Komo, Anish Senapati
<br />
<i>West Windsor-Plainsboro High School South</i> - Anurag Bakshi, Kevin Li, Bill Huang, Anish Visaria
<br />
<i>Conestoga High School</i> - Dennis Lee, Jeffrey Huang, Michael Tao, Narahari Bharadwaj
<br /><br />
The top 2 Novice teams were:
<br />
1. <i>Unaffiliated from Virginia</i> - Cecelia Chu, Hayun Chong, William Xu, Landon Chu
<br />
2. <i>Conestoga High School</i> - Jason Tu, Orion Forowycz, Aaron Lee
<br /> <br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br /><br/>
The complete scoreboard is up <a href="https://docs.google.com/spreadsheets/d/1-U7sA9zpt4_-GxcZDRvcKWjxAmv2TwXzxeir5DQXRDQ/edit?usp=sharing">here</a>, though the data only indicates number solved; tie breakers were hand-verified during the contest.
<br /> <br />

This year's question set is <a href="/files/problems/PClassic2015sProblems.pdf">online</a> and the test cases and solutions are available <a href="/files/problems/PClassic2015sSolutions.zip">as a zip file</a>.
<br />
Pictures from the event are up on our <a href="https://www.facebook.com/pages/PClassic/157577100964142">Facebook page</a>.
</p>

    </div>
  )
}

export default s2015