import React from 'react'
function s2014() {
  return (
    <div>
        <h4>PClassic 2014 Spring Summary</h4>
<p>
PClassic 2014s happened on April 26th, 2014. It saw 150 participants on over 40 teams from 20 schools participating, coming from Pennsylvania, Maryland, New Jersey, and New York. </p>
<br />
<br />
Our runners-up from last semester returned to take gold in a photo-finish, submitting their final solution with just 10 seconds to spare!
<br />
<br />
<p>
The top 3 teams this year were:
<br />
1) <i>Unaffiliated from Virginia</i> - Daniel Hussain, Hyo Won Kim, Matthew Savage, Corwin De Boor
<br />
2) <i>West Windsor Plainsboro HS North</i> - Tanvi Bajpai, Manyu Sharma, Aaron Berger, Alok Tripathy
<br />
3) <i>West Windsor Plainsboro HS North</i> - Aaron Weiss, Benjamin Edelman, Jacob Edelman, Ernest Chiu
<br /> <br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br />
<br/>
Congratulations also to West Windsor Plainsboro HS North Team 0xFC0 (Mahesh Khanwalkar) for winning the novice division!
<br/><br/>
The scoreboard is up with all teams' results <a href="https://docs.google.com/spreadsheets/d/1inlgbGcS0WXUzoGQS9445Mjt5KTg5zjIiDNSwmZ67cQ">here</a>.
<br /> <br />


This semester's problem set is <a href="/files/problems/PClassic2014sProblems.pdf">online</a> and the test cases and solutions are available <a href="/files/problems/PClassic2014sSolutions.zip">as a zip file</a>.
</p>

    </div>
  )
}

export default s2014