import React from 'react'
function s2016() {
  return (
    <div>
<h4>PClassic 2016 Spring Summary</h4>
<p>
PClassic happened this spring, on April 23rd, 2016. We had  around 200 students on 70 teams from 30 schools making this the largest spring contest to date! </p>
<br />
<br />
<p>
The top 3 teams this year in the Standard division  were:
<br />
1. <i>Unaffiliated</i> -  Michael Kural
<br />
2. <i>Unaffiliated</i> - Davin Park, Anson chen, Thomas Luo
<br />
3. <i>West Windsor-Plainsboro High School North</i> - Ezra Edelman, Jacob Edelman, Jakob Degan, Eitan Zlatian
<br />
<br />
The top Novice team was:
<br />
1. <i>Charter School of Wilmington</i> - Adithya Dattatri, Harry Wang, Ben Fickes
<br />
<br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br /><br/>

This year's question set is <a href="/files/problems/PClassic2016sQuestions.pdf">online</a> and the test cases and solutions are available <a href="https://www.dropbox.com/s/7bf8blrkdilt7vd/solutions.zip?dl=0">as a zip file</a>.
<br />
</p>

    </div>
  )
}

export default s2016