import React from 'react'

function f2013() {
  return (
    <div>
        <h4>PClassic 2013 Fall Summary</h4>
<p>
PClassic happened in the fall for the first time, on November 16th, 2013. It continued to grow, with 300 students on 80 teams from over 20 schools participating, coming from Pennsylvania, Maryland, New Jersey, New York, Massachusetts, and even Florida! </p>
<br />
<br />
<p>
The top 3 teams this year were:
<br />
1) <i>Montgomery Blair High School</i> - Kiyoon Ko, Scott Wu, Vijay Upadhya, Megan Chao
<br />
2) <i>Thomas Jefferson High School</i> - Daniel Hussain, Hyo Won Kim, Mathew Savage, Corwin De Boor
<br />
3) <i>Friends' Central School</i> - John Gallagher, Alex Nichol, Daniel Nemroff, Samuel Kruger
<br /> <br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br />
<br/>
Congratulations also to Pennsbury Team 3 (Connor Watts, Ryan Stumbaugh, Jason Fields, Sean Sibner) for winning the novice division!
<br/><br/>
The scoreboard is up <a href="https://docs.google.com/spreadsheet/ccc?key=0AqKkQjZRbUBHdEI4d2pRTGVIU2hpNmxTM2x1NnNpUVE&usp=drive_web#gid=0">here</a>.
<br /> <br />


This year's question set is <a href="/files/problems/PClassic2013fProblems.pdf">online</a> and the test cases and solutions are available <a href="files/problems/PClassic2013fSolutions.zip">as a zip file</a>.
</p>

    </div>
  )
}

export default f2013