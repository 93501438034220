import React from 'react'
function f2017() {
  return (
    <div>

<h4>PClassic 2017 Fall Summary</h4>
<p>
PClassic took place on November 18th, 2018. We had over 300 students on 70 teams from 30 schools making this the largest fall contest to date! </p>
<p>
The top 3 teams this year in the Standard division  were:
<br />
3. <i>Montgomery Blair High School</i> -  Arman Siddique, Noah Singer, Siddharth Taneja, Andrew Komo
<br />
2. <i>Unaffiliated</i> - Chris Lambert
<br />
1. <i>West Windsor-Plainsboro High School North</i> - Ezra Edelman, Jacob Edelman, Jakob Degan, Eitan Zlatian
<br />
<br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br /><br/>

This year's question set is <a href="/files/problems/PClassic2017fProblems.pdf">online</a> and the test cases and solutions are available as a zip file.
<br />
</p>

    </div>
  )
}

export default f2017