import React from 'react'
function f2018() {
  return (
    <div>
        <h4>PClassic 2018 Fall Summary</h4>
<p>
PClassic took place on November 16th, 2018. We had over 300 students on 83 teams from 30 schools! </p>
<p>
The top 3 teams this year in the Standard division were:
<br />
1. <i>Unaffiliated</i> - Chris Lambert, Ahila Moorthy
<br />
2. <i> Princeton High School</i> - Chris Pan, Andrew Xie
<br />
3. <i>Thomas Jefferson High School for Science and Technology</i> - Havish Malladi, Aneesh Boreda, Patrick Zhang, Omkar Bhalerao
<br />
<br />
The top team in this Novice division was:
<br />
<i>Conestoga High School</i> - Matt G, Mihir D, Rohit C
<br />
<br />
Congratulations to the winning teams!  <br /><br/>

This year's question set is <a href="/files/problems/PClassic2018fProblems.pdf">online</a> and the test cases and solutions are available as a zip file.
<br />
</p>
    </div>
  )
}

export default f2018