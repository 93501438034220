import React from 'react'
function s2022() {
  return (
    <div>
        <h4>PClassic Spring 2022 Summary</h4>

<p>
Contest Date: <b>Saturday, April 9 2022</b><br/>
Location: Virtual on <b>CodeChef</b>
</p>

<p>
PClassic had their second virtual competition, hosted on CodeChef [<a href="https://www.codechef.com/PCSN2022">Novice</a>, <a href="https://www.codechef.com/PCSS2022">Standard</a>]! The competition 
took place on Saturday April 9th, 2022.
<br/><br/>
The contest theme this year was countries!<br/>
1st place a trophy, a free PClassic shirt, and a prize item.<br/>
2nd place received a trophy and a free shirt.<br/>
3rd place received a trophy.<br/>
Prize item is a choice between: Amazon Echo Dot, Mechanical Keyboard, Steam Gift Card.<br/>
<i>most people chose the steam gift card!</i>
</p>

<p>
The top 3 teams this year in the Standard division were:
<br />
1) (a) <i>Henry M. Gunn High School</i> and (b) <i>Newark Academy</i> - Stanley Zhong (a), William Tsin (a), Ian Zhang (b)
<br />
2) <i>Thomas Jefferson High School for Science and Technology</i> - Alvan Arulandu, Edward Li, Kevin Shan, Joshua Zhang
<br />
3) <i>Stuyvesant High School</i> - Mohammad Khan, Alvin Li, Jeremy Ku-Benjet, Maxwell Zen
<br />
<br />
The top team in this Novice division was: 
<br />
<i> West Windsor-Plainsboro High School South </i> - Sayam Goyal, Rishikesh Anabathula
<br/>
<br/>
Congratulations to the winning teams!<br/>
<b>We have unfrozen the rank list. You should be able to see the rank list now on the CodeChef website!</b>
 <br/><br/></p>

<h4>Past Announcements</h4>
<p>We have a tech talk from 10-11am featuring University of Pennsylvania professor Chris Callison-Burch! He teaches and researches Artificial Intelligence and Computational Linguistics. The topic of the tech talk will be about Artificial Intelligence! </p>

<p><a href="https://upenn.zoom.us/j/99009027227">Zoom</a> <b>10:00 am - 11:00 am</b>: Tech Talk with Professor Callison-Burch</p>
<p><a href="https://youtu.be/ly5YqoD5jlg">YouTube</a> <b>11:00 am - 12:00 pm</b>: <a href="https://docs.google.com/presentation/d/1cfjMIq1rb4Kvy6GBFbn-fhon5nnOddaJJ263R_auBIc/edit?usp=sharing"></a>Opening</p>
<p>[<a href="https://www.codechef.com/PCSN2022">Novice]</a> [<a href="https://www.codechef.com/PCSS2022">Standard</a>] <b>12:00 pm - 4:00 pm</b>: Contest on CodeChef</p>
<p><a href="https://upenn.zoom.us/j/95230762623">Zoom</a> <b>12:00 pm - 4:00 pm</b>: Contest Help Desk</p>
<p><a href="https://www.youtube.com/watch?v=-fE4vLZ313o">YouTube</a> <b>4:00 pm - 4:30 pm</b>: Closing - Q+A</p>
<p><a href="https://youtu.be/1toOqr2cLMc">YouTube</a> <b>4:30 pm - 4:45 pm</b>: <a href="https://docs.google.com/presentation/d/1VVKGQVTsHW2xVmpfA-VeFXAP-6zh0HSRymmFpI4IOGA/edit#slide=id.g123b2fd57c2_0_1775">Closing</a> - Winners</p>

<p>Other links: <a href="https://tinyurl.com/pclsp22shirts">shirts</a> <a href="https://tinyurl.com/pclsp22feedback">feedback</a></p>


    </div>
  )
}

export default s2022