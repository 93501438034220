import React from 'react'
function s2018() {
  return (
    <div>
        <h4>PClassic 2018 Spring Summary</h4>
<p>
PClassic took place on April 7th, 2018. We had over 200 students on 50 teams from 30 schools! </p>
<p>
The top 3 teams this year in the Standard division were:
<br />
1. <i>West Windsor-Plainsboro High School North</i> -  Akshit Dewan, Alan Yan, Parth Shastri
<br />
2. <i>West Windsor-Plainsboro High School North</i> - Varun Ramani, Arnav Rastogi, Jasper Xia, Pratyush Tulsian
<br />
3. <i>Stuyvesant High School</i> - Crystal Wang, Robin Han, Peter Jin, Haoxuan Liu
<br />
<br />
The top team in this Novice division was: 
<br />
<i> Stuyvesant High School </i> - Cathy Cai, Fiona Cai, Jeffrey Wu, Jason Lin 
<br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br /><br/>

This year's question set is <a href="/files/problems/PClassic2018sProblems.pdf">online</a> and the test cases and solutions are available as a zip file.
<br />
</p>

    </div>
  )
}

export default s2018