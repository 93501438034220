import React from 'react'
function f2019() {
  return (
    <div>
<h4>PClassic 2019 Fall Summary</h4>
<p>
PClassic took place on November 23rd, 2019. We had over 320 students on 102 teams from 30 schools! </p>
<p>
The top 3 teams this year in the Standard division were:
<br />
1. <i>Montgomery Blair High School</i> - Gabriel Wu, Timothy Qian, Colin Galen, Kevin Higgs
<br />
2. <i>Thomas Jefferson High School for Science and Technology</i> - Omkar Bhalerao, Danny Mittal, Patrick Zhang, Ray Bai
<br />
3) <i>Stuyvesant High School</i> - Ethan Morgan, Ivan Galakhov
<br />
<br />
The top team in this Novice division was: 
<br />
<i> Brandywine High School </i> - Anand John, Jonathan Pei
<br />
<br />
Congratulations to the winning teams! <br /><br/>

This year's question set is <a href="/files/problems/PClassic2019fProblems.pdf">online</a> and the test cases and solutions are available as a zip file.
<br />

<p>
In this contest, Penn Lecturer Adam Mally gave a talk on computer graphics and design. Here are the relevant links:
<br/> 
<a href="https://www.shadertoy.com/user/iq/sort=popular&from=0&num=8">https://www.shadertoy.com/user/iq/sort=popular&from=0&num=8</a>
<br/> 
<a href="https://www.iquilezles.org/apps/graphtoy/index.html">https://www.iquilezles.org/apps/graphtoy/index.html</a>
<br/>
<a href="https://github.com/mxgmn/WaveFunctionCollapse">https://github.com/mxgmn/WaveFunctionCollapse</a>
<br/>
<a href="https://github.com/sgalban/platformer-gen-2D">https://github.com/sgalban/platformer-gen-2D</a>
<br/>
<a href="https://docs.google.com/presentation/d/1gm7Vnr98wBbg2CiTyXIjJYYTKvAjvROk96_gUTMXao0">https://docs.google.com/presentation/d/1gm7Vnr98wBbg2CiTyXIjJYYTKvAjvROk96_gUTMXao0</a>
<br/>
<a href="https://www.youtube.com/watch?v=42R-isPePdA&feature=youtu.be">https://www.youtube.com/watch?v=42R-isPePdA&feature=youtu.be</a>
<br/>
<a href="https://vimeo.com/246804026">https://vimeo.com/246804026</a>
<br/>
<a href="https://github.com/LanLou123/Webgl-Erosion">https://github.com/LanLou123/Webgl-Erosion</a>
<br/>
<a href="https://github.com/whaoran0718/hw04-l-systems">https://github.com/whaoran0718/hw04-l-systems</a>
<br/>
<a href="http://www.kevs3d.co.uk/dev/lsystems/">http://www.kevs3d.co.uk/dev/lsystems/</a>
</p>
</p>
    </div>
  )
}

export default f2019