import React from 'react'
function s2019() {
  return (
    <div>
        <h4>PClassic 2019 Spring Summary</h4>
<p>
PClassic took place on March 30th, 2019. We had over 240 students on 75 teams from 30 schools! </p>
<p>
The top 3 teams this year in the Standard division were:
<br />
1. <i>Montgomery Blair High School</i> - Gabriel Wu, Timothy Qian, Kevin Higgs, Maxwell Zhang
<br />
2. <i>Stuvyesant High School</i> - Ethan Morgan, Ivan Galakhov, Sam Rosenstrauch, Vincent Fong
<br />
3. <i>Unaffiliated</i> - Ray Bai, George Tang, Rahul Mittal, Saketh Gabbita
<br />
<br />
The top team in this Novice division was: 
<br />
<i> High School North </i> - Arvyn De, Anshul Tripathy, Allen Chang, Jason Chang
<br />
<br />
Congratulations to the winning teams! <br /><br/>

This year's question set is <a href="/files/problems/PClassic2019sProblems.pdf">online</a> and the test cases and solutions are available as a zip file.
<br />
</p>

    </div>
  )
}

export default s2019