import React from 'react'
function f2016() {
  return (
    <div>
<h4>PClassic 2016 Fall Summary</h4>
<p>
PClassic happened this fall, on November 14th, 2016. We had  around 300 students on 90 teams from 30 schools making this one of our largest contests ever! </p>
<br />
<p>
The top 3 teams this year in the Standard division  were:
<br />
1. <i>Unaffiliated</i> -  Shwetark Patel, Srinidhi Krishnamurthy, Justin Zhang, Franklyn Wang
<br />
2. <i>Stuyvesant</i> - Vandana Agarwala
<br />
3. <i>Montgomery Blair</i> - Guangqi Cui, Dilhan Salgado, David Wu, Zan Xu
<br />
<br />
The top Novice team was:
<br />
1. <i>Charter School of Wilmington</i> - Joshua Fickes, Jesse Li, Harry Wang, Arushi Tayal
<br />
<br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br /><br/>

Unfortunately we cannot provide a full ranking of the teams but we have a breakdown of the number of questions solved for each division.</p>
<br />
In the standard division:
<ul>
<li>15 teams solved 1 question</li>
<li>11 teams solved 2 questions</li>
<li>1 team solved 3 questions</li>
<li>11 teams solved 4 questions</li>
<li>7 teams solved 5 questions</li>
<li>4 teams solved 6 questions</li>
<li>3 teams solved 7 questions</li>
<li>1 team solved 8 questions</li>
</ul>
In the novice division:
<ul>
<li>3 teams solved 1 question</li>
<li>7 teams solved 2 questions</li>
<li>3 teams solved 3 questions</li>
<li>4 teams solved 4 questions</li>
<li>13 teams solved 5 questions</li>
<li>4 teams solved 6 questions</li>
<li>0 teams solved 7 questions</li>
<li>1 team solved 8 questions</li>
</ul>
This year's question set is <a href="/files/problems/PClassic2016fProblems.pdf">online</a> and the test cases and solutions are available <a href="/files/problems/PClassic2016fSolutions.zip">as a zip file</a>.
<br />

    </div>
  )
}

export default f2016