import React from 'react'
function f2021() {
  return (
    <div>
<h4>PClassic Fall 2021 Summary</h4>

<p>
PClassic had their first virtual competition, hosted on CodeChef [<a href="https://www.codechef.com/PCFN2021">Novice</a>, <a href="https://www.codechef.com/PCFL2021">Standard</a>]! The competition 
took place on Saturday November 20th, 2021.
<br/><br/>
The contest theme this year was animals that begin with P!<br/>
1st place a trophy, a free PClassic shirt, and a prize item.<br/>
2nd place received a trophy and a prize item.<br/>
3rd place received a trophy.<br/>
Prize item is a choice between: Amazon Echo Dot, Mechanical Keyboard, Otamatone.
</p>

<p>
The top 3 teams this year in the Standard division were:
<br/>
1. (a) <i>Brandywine High School</i> and (b) <i>The Charter School of Wilmington</i> - Jonathan Pei (a), Anand John (a), Nathan Zhao (b), Jack Wu (b)
<br/>
2. <i>Stuyvesant High School</i> - Maxwell Zen, Alvin Li, Mohammad Khan, Jeremy Ku-benjet
<br/>
3. (a) <i>BASIS Independent Silicon Valley</i>, (b) <i>Newark Academy</i> and (c) <i>Henry M. Gunn High School</i> - Elijah Huang (a), Ian Zhang (b), Stanley Zhong (c), William Tsin (c)
<br/>
<br/>
The top team in this Novice division was: 
<br/>
<i> River Hill High School </i> - Rayhan Zirvi, Florin Selaru, William Zhong
<br/>
<br/>
Congratulations to the winning teams! <br/><br/></p>

<p>Novice Ranks<br/><br/>
<pre>
{`Rank   Team Name                   Username         Score   Penalty   School                                                      
1      Chad Boothe                 chadboothe23     8       4         River Hill High School                                      
2      Uwu Femboys                 tstark7          7       11        River Hill High School                                      
3      Shreyas Fanboys             sharmar6         7       12        West Windsor-Plainsboro High School South                   
4      PRISMS11                    prisms11er       6       3         Princeton International School of Mathematics and Science   
5                                  ryanlau          5       4         Stuyvesant High School                                      
6      The Leetcode Grinders       leetcode_grind   5       2         West Windsor-Plainsboro High School South                   
7      Minoblue (Novice)           boboypablo       5       6         home schooled                                               
8      Epic Pizza (Novice)         hydro_comet      5       2         West Windsor-Plainsboro High School North                   
9      The Llamas                  rebekah_wang     5       2         West Windsor-Plainsboro High School South                   
10     Shrooms                     shroomicus       5       7         Stuyvesant High School                                      
11     Davis's goobers             episcopal_a      5       9         The Episcopal Academy                                       
12     Recursion Gamers (Novice)   rehmetm014       5       14        Central Bucks High School South                             
13     Code Minds (Novice)         codeminds        4       2         North Allegheny School District                             
14     Array of [Swag]             eduardoloz       4       0         Stuyvesant High School                                      
15     Assemblers (Novice)         alex_ak          4       0         Central Bucks High School South                             
16     Nutcrackers                 yantayn1         4       3         Stuyvesant High School                                      
17     troots                      thepigeonnyc     4       3         Stuyvesant High School                                      
18     Falcons                     falcons24        4       4         West Windsor-Plainsboro High School South                   
19     swag fault                  mccauleyr4       3       2         Lawton Chiles High School                                   
20     The Terrible Trifecta       cchen32          3       1         Stuyvesant High School                                      
21     Davis Tacos                 episcopal_b      3       6         The Episcopal Academy                                       
22     Minogreen (Novice)          minocode         3       16        home schooled                                               
23     The Lonely Penguin          dchen30          2       0         Stuyvesant High School                                      
24     abunabu (Novice)            varun427         2       1         Central Bucks High School South                             
25     πrate                       piratedfilm      2       1         Richard Montgomery High School                              
26     HSESupremeCoders(Novice)    destroyer1645    2       1         Half Hollow Hills High School East                          
27     Blue Backpack Kids          anson1           2       3         Stuyvesant High School                                      
28     javaholic                   ffxjava          2       4         FFX Java Tutoring                                           
29     Red Panda                   blurav           1       0         Upper Merion Area High School                               
30     Arabian Knights             saminsarker      1       0         Stuyvesant High School                                      
31     team3 (Novice)              sohamkakadia29   1       0         Central Bucks High School South                             
32     Rohan's Team (Novice)       bionicro1        1       0         Conestoga High School                                       
33     Dans                        sixsix10         1       0         Upper Darby High School                                     
34     Brans                       brandon130       1       0         Upper Darby High School                                     
35     Speedy Sloths               stylos           1       2         Richard Montgomery High School                              
36     ADo                         alexander_do     1       6         LaSalle Institute                                           
`}</pre>
</p>

<p>Standard Ranks<br/><br/>
<pre>
{`Rank   Team Name                      Username         Score   Penalty   School                                                      
1      Calcoholics                    radiantcheddar   8       9         Brandywine High School, The Charter School of Wilmington   
2      Floofers                       zaxwellmen       7       7         Stuyvesant High School                                     
3      sag tree lazy prop a gaters    lazy_sag_tree    6       2         Henry M. Gunn High School                                  
4      /[spin]*s/                     spinstars        6       7         West Windsor-Plainsboro High School North                  
5      PRISMS01                       prisms01er       6       11        Princeton International School of Mathematics and Science  
6      Team RADD                      mutya            5       5         Half Hollow Hills High School East                         
7      Virada                         durzal           4       6         Wissahickon High School                                    
8      Edgemont Programming Team      emschneyer       4       3         Edgemont High School                                       
9      Super Idol                     happiestbee      4       5         Montgomery Blair HS                                        
10     We need three pointers         envyaims         4       10        Stuyvesant High School                                     
11     hmmm                           superstormer     4       11        West Windsor-Plainsboro High School North                  
12     PPClassic                      ppclassic        4       6         Stuyvesant High School                                     
13     NERK                           ericsun          3       3         Westford Academy                                           
14     Filler Name                    dennis_miczek    3       4         Garnet Valley High School                                  
15     Half-Nerd                      half_nerd        3       4          unaffiliated                                              
16     Brentwood Coding Club          jackr123         3       3         Brentwood School                                           
17     blair blezers                  czhang2718       3       4         Montgomery Blair HS                                        
18     Shnucks                        shnucks          3       3         West Windsor-Plainsboro High School South                  
19     PAnts                          pants            3       5         Westford Academy                                           
20     Bing Qi Ling                   benjaminwu13     2       1         Garnet Valley High School                                  
21     Stonks                         bobsonjoe        2       2         West Windsor-Plainsboro High School North                  
22     PRISMS10                       prisms10er       2       1         Princeton International School of Mathematics and Science  
23     Le Fishe Au Chocolat           gthompson30      2       1         Stuyvesant High School                                     
24     Battlefield Garbage            lee53            2       1         Battlefield High School                                    
25     Team 3zik                      ryanofarrell     2       4         Wissahickon High School                                    
26     Ithaca Javacados               om17             2       0         Ithaca High School                                         
27     Gavin's Team                   gavincodes123    2       2         Conestoga High School                                      
28     rubberducks                    rubberducks      2       0         West Windsor-Plainsboro High School South                  
29     insert something original      miik3y           2       4         West Windsor-Plainsboro High School South                  
30     GuilderlandA                   guilderland_a    2       11        Guilderland High School                                    
31     Dominik's Team                 dygitdacoder     2       5         Conestoga High School                                      
32     N Queens                       nqueens          1       0         West Windsor-Plainsboro High School North                  
33     Battlefield Marriage           blaze_123        1       0         Battlefield High School                                    
34     Tean's Team                    jeffy85          1       0         Conestoga High School                                      
35     We couldn't find a team name   shiftkei         1       2         The Episcopal Academy                                      
36     i believe                      owhan            1       2         Los Altos High School                                       
`}</pre>
</p>

<p>
10:00 am - AMA: <a href="https://youtu.be/pXn3FCc8LuI">Youtube Live</a> 
<br/>
11:00 am - Opening: <a href="https://youtu.be/U35dXlFs3Ak">Youtube Live</a>
<br/>
12:00 pm - Contest Problem Clarifications: <a href="https://upenn.zoom.us/j/97070644220">Zoom</a>
<br/>
Note: Join the Zoom only if you have a question
<br/>
4:00 pm - Closing: <a href="https://youtu.be/ItK2698uHT0">Youtube Live</a>
</p>

<h4>Past Announcements</h4>

<p>[11/9] - There is now an AMA from 10 am to 11 am with Meggie Cheng and Vatsin Suchak, two seniors in PClassic who have worked in the Gaming Industry and FAANG as Software Engineers! Is there anything you want them to talk about or answer? Ask <a href="https://forms.gle/vy5jLicMWP9cewUM9">here</a>! Feel free to also ask in the chat during the live session!
</p>
<br/>

<p>[10/28] - Hello everyone! <a href="https://forms.gle/L7o1h7tjfrthGTA89">Registration</a> has opened for the contest! Each team will need to provide a CodeChef username to gain access to the private competition. Please do so by <b>Sunday Nov 14</b>. The new rules are in a doc under the Rules tab.
</p>
<br/>

<p>
[9/15] - After a year and a half hiatus, we are excited to announce that we will finally be having an online coding competition this semester! It is planned to be on a Saturday mid-November. More details about the competition and forms will be out soon.
We are truly sorry for the long wait and are looking forward to seeing you all again!</p>


    </div>
  )
}

export default f2021