import React from 'react'
function f2015() {
  return (
    <div>
        <h4>PClassic 2015 Fall Summary</h4>
<p>
PClassic happened this fall, on November 14th, 2015. We had  around 270 students on 82 teams from over 35 schools. Participants came from Pennsylvania, Maryland, New Jersey, and New York! </p>
<br />
<br />
<p>
The top 3 teams this year in the Standard division  were:
<br />
1. <i>Stuyvesant High School</i>, 6/9 correct - Matthew Lerner-Brecher, Max Fishelson, Yicheng Wang, Dennis Yatunin
<br />
2. <i>Montgomery Blair High School</i>, 4/9 correct (0 incorrect submissions) - Arman Siddique, Andrew Komo, Anish Senapati, Noah Singer
<br />
3. <i>West Windsor-Plainsboro High School North</i> 4/9 correct (1 incorrect submission) - Ezra Edelman, Jacob Edelman, Mahesh KhanWalkar, Sammy Berger
<br />
<br />
The top Novice team was:
<br />
1. <i>Marriotts Ridge High School</i> 7/9 correct - Davin Park, Anson Chen
<br />
<br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br /><br/>
You can view how many problems your team solved <a href="https://docs.google.com/spreadsheets/d/1emAC0vINNh1Xw8-fZxUDLajN5uQK23WTHI6S0qqbusA/pubhtml">here</a> (novice and non-novice teams are integrated so its not really a scoreboard). 
<br /> <br />

This year's question set is <a href="/files/problems/PClassic2015fProblems.pdf">online</a> and the test cases and solutions are available <a href="/files/problems/PClassic2015fSolutions.zip">as a zip file</a>.
<br />
Pictures from the event are up on our <a href="https://www.facebook.com/pages/PClassic/157577100964142">Facebook page</a>.
</p>

    </div>
  )
}

export default f2015