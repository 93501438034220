import React from 'react'
function s2012() {
  return (
    <div>
        <h4>
PClassic 2012 Summary
</h4>

<p>
PClassic 2012 was the largest PClassic yet! Over 30 teams from 16 schools in Pennsylvania, New Jersey, Maryland, and New York participated. A full participating school list is at the bottom of this post.
<br /> <br />
The top 3 teams this year were:
<br />
1) <i>Methacton High School</i> - null: Jason Carr, Patrick Lebold, Neel Mehta, Sherry Yang
<br />
2) <i>Sherwood High School</i> - Warriors: Jon Egeland, Kyle McLamb, Darshan Shah, Craig Weiss
<br />
3) <i>Conestoga High School</i> - Mildred: Jed Thompson, Jichao Sun, Scott Shi, Allen Zhu
<br /> <br />
This year's contest had 10 problems, worth up to 10 points each. The top 3 teams all tied for 60/100 points, and the tie was broken on number of submissions.

<br /> <br />
Other teams rounding out the top of the chart were:
<br />
4) Germantown Friends - Science New 4: 57 points
<br />
5) Dalton School - strictfp: 54 points
<br />
6) Princeton Day School - JV Spelling Team: 50 points
<br />
6) Wissahickon High School - Gosu: 50 points
<br />
6) Methacton High School - Starlinear: 50 points
<br /> <br />

Full team results can be seen <a href="https://docs.google.com/spreadsheet/ccc?key=0Aha_Rxus9XXSdHkwSmhxYXRhdXJJRWViU3ViREV4Mmc#gid=0">here</a>
<br /> <br />

Participating schools were:
<br />
Conestoga (2)
<br />
Dalton (2)
<br />
Downington East (1)
<br />
East Brunswick (7)
<br />
Friends Select (1)
<br />
Germantown Friends (1)
<br />
Half Hollow Hills East (3)
<br />
Horace Mann (2)
<br />
Methacton (3)
<br />
Point Pleasant Computing Club (3)
<br />
Princeton Day School (3)
<br />
Sherwood (1)
<br />
Springfield Township (1)
<br />
St. Joseph's Prep (1)
<br />
Wissahickon (2)
<br />
<br />

A copy of this year's questions (with errata incorporated) is up <a href="/files/problems/PClassic2012Problems.pdf">here</a>
</p>

    </div>
  )
}

export default s2012