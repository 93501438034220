import React from 'react'
function f2014() {
  return (
    <div>
        
        <h4>PClassic 2014 Fall Summary</h4>
<p>
PClassic happened in the fall for the second time, on December 6th, 2014. It grew to its largest size yet, with 350 students on 100 teams from over 30 schools. Participants came from Pennsylvania, Maryland, New Jersey, New York, Virginia, and even Florida! </p>
<br />
<br />
<p>
The top 3 teams this year were:
<br />
1. <i>Thomas Jefferson High School</i> - Samuel Kim, Rajat Khanna, Haoyuan Sun, Lalit Varada
<br />
2. <i>Thomas Jefferson High School</i> - Corwin de Boor, Danial Hussain, Hyo Won Kim, Matthew Savage
<br />
3. <i>West Windsor Plainsboro HS North</i> - Alok Tripathy, Brice Huang, Christopher Shao, Jacob Edelman
<br /> <br />
The top 3 Novice teams were:
<br />
1. <i>Stuyvesant High School</i> - Raymond Wu, Haoran Xiao
<br />
2. <i>Methacton High School</i> - Ananth Dandibhotla, Nick DeFlippis, William Guilian, Joshua Liu
<br />
3. <i>Horace Mann School</i> - Jeffrey Weiner, Jason Ginsberg, Austin Sanders
<br /> <br />
Congratulations to the winning teams! Prizes included trophies and free space on <a href="http://dropbox.com">Dropbox</a>! <br /><br/>
The complete scoreboard is up <a href="https://docs.google.com/spreadsheets/d/1JdLaq_z6miNRl1v3SsAN8Jyxr_elcN4FEnDlXGtN7Ho/view">here, though the data has only been hand-verified for the winners</a>.
<br /> <br />

This year's question set is <a href="/files/problems/PClassic2014fProblems.pdf">online</a> and the test cases and solutions are available <a href="/files/problems/PClassic2014fSolutions.zip">as a zip file</a>.
</p>

    </div>
  )
}

export default f2014