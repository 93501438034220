import React from 'react'
function f2022() {
  return (
    <div>
        <h4>PClassic Fall 2022 Summary</h4>

<p>
Contest Date: <b>Saturday, November 19 2022</b><br/>
Location: Virtual on <b>CodeChef</b>
</p>

<p>
PClassic had their third virtual competition, hosted on CodeChef [<a href="https://www.codechef.com/PCFN2022">Novice</a>, <a href="https://www.codechef.com/PCFS2022">Standard</a>]! The competition 
took place on Saturday November 19th, 2022.
<br/><br/>
The contest theme this year was AI Takeover!<br/>
1st place a trophy and an Amazon Gift Card.<br/>
2nd place received a trophy and an Otamatone.<br/>
3rd place received a trophy.<br/>
</p>

<p>
The top 3 teams this year in the Standard division were:
<br/>
1) <i>Brandywind High School</i> - Anand John, <i>Adlai E. Stevenson High School</i> - Benjamin Chen, <i>Monta Vista High School</i> - Rohin Gard, and <i>American High School</i> - Varun Ragunath
<br/>
2) <i>Lexington High School</i> - Eric Yang, <i>Allen High School</i> - Austin Geng, <i>Benjamin Franklin High School</i> - Allen Wu, <i>Newport High School</i> - Alex Fan
<br/>
3) <i>West Windsor-Plainsboro High School South</i> - Mateusz Klosin
<br/>
<br/>
The top team in this Novice division was: 
<br/>
<i> West Windsor-Plainsboro High School South</i> - Rishikesh Anabathula, Sayam Goyal, Poojit Majeti, Shasheen Battacharya
<br/>
<br/>
Congratulations to the winning teams!<br/>
<b>We have unfrozen the rank list. You should be able to see the rank list now on the CodeChef website!</b>
 <br/><br/></p>

<h4>Past Announcements</h4>
<p> We have a Tech Talk with Professor Travis McGaha at 10 am! The topic of the tech talk is: <b>Intro to the Field of Systems and Computer Science</b></p>

<p>10:00 AM: Tech Talk [<a href="https://upenn.zoom.us/j/8258795174">Zoom</a>]</p>
<p>11:00 AM: Kick Off [<a href="https://www.youtube.com/watch?v=9vZDrwt_A7w">Youtube</a>]</p>
<p>12:00 PM: Contest [CodeChef: <a href="https://www.codechef.com/PCFN2022">Novice</a> | <a href="https://www.codechef.com/PCFS2022">Standard</a> | <a href="https://upenn.zoom.us/j/8258795174">Zoom</a>]</p>
<p>4:00 PM: Q/A and Awards [<a href="https://www.youtube.com/watch?v=-TENT2jDxIo">Youtube</a>]</p>
<p><a href="https://forms.gle/ab62GBvmZ7raeAPA9">Feedback Form</a></p>

<p>To register for your team, sign up <a href="https://forms.gle/3nFPWsopqCQf8a3D7">here</a>! Note that we may finally have our long yearned in person competition in the spring! Additionally, our new and updated website is planned to release in the spring! Stay tuned for more updates as we are always striving to make our contest better each year!</p>

<p>Other links: <a href="https://forms.gle/ab62GBvmZ7raeAPA9">feedback</a></p>


    </div>
  )
}

export default f2022